import { configureStore } from '@reduxjs/toolkit'
import adminReducer from './reducers/adminReducer'
import parentReducer from './reducers/parentReducer'

export default configureStore({
  reducer: {
    parent: parentReducer,
    admin: adminReducer
  }
})
