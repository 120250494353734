import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import footerBg from 'assets/images/footer.png'
import logoImg from 'assets/images/logo.svg'
import asobiImg from 'assets/images/asobi.png'
import upbondImg from 'assets/images/upbond.png'
import kingdomImg from 'assets/images/kingdom.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { SmallFooter } from './SmallFooter'
import './footer.scss'

const Footer = ({logo = false}) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('jp');

  useEffect(() => {
    if ((localStorage.getItem('i18nextLng') || '').includes('en')) {
      setLang('en')
    } else {
      setLang('jp')
    }
  }, [localStorage.getItem('i18nextLng')])
  
  console.log("envnya", process.env.REACT_APP_ENV)
  
  const onClickPage = async (page) => {    
    if (page === 'top') navigate(`/`)
    if (page === 'about') navigate(`/about`)
    if (page === 'roadmap') navigate(`/roadmap`)  
    if (page === 'privacy') navigate(`/privacy-policy`)  
    if (page === 'our-team') navigate(`/our-team`)    
    if (page === 'guideline') navigate(`/guideline`)  
    if (page === 'special-commercial') navigate(`/special-commercial`)    
    if (page === 'term-of-use') navigate(`/term-of-use`)    
  }

  return (
    <div className="w-full h-full relative grid">
      {logo && <div className="hero-footer"></div>}
      <div className="footer">
        <div className="wrapper">
          <img src={logoImg} className="w-56 mb-8" />
          <div className="sm:flex justify-between">
            <div className="grid footer-space">
              <div className="text" onClick={()=> onClickPage('top')}>TOP</div>
              <div className="text" onClick={()=> onClickPage('about')}>about</div>
              <div className="text" onClick={()=> onClickPage('roadmap')}>road map</div>
              <div className="text" onClick={()=> onClickPage('our-team')}>team</div>
            </div>
            <div className="grid">
              {/* {(process.env.REACT_APP_ENV === 'development') && <div className="subtext" onClick={()=> onClickPage('privacy')}>プライバシーポリシー</div>} */}
              <div className="subtext" onClick={()=> onClickPage('privacy')}>{t("footer_privacy_policy")}</div>
              <div className="subtext" onClick={()=> onClickPage('guideline')}>{t("footer_guideline")}</div>
              <div className="subtext" onClick={()=> onClickPage('special-commercial')}>{t("special_commercial")}</div>
              <div className="subtext" onClick={()=> onClickPage('term-of-use')}>{t("term_of_use")}</div>
              <div className="invisible">pad</div>
              <div className="invisible">pad</div>
              {/* <div className="subtext">利用規約</div>
              <div className="subtext">利用規約特定商取引法</div> */}
            </div>
          </div>
          <div className="sm:flex justify-between">
            <div className="support">
              <img src={asobiImg} className="client" />
              <img src={upbondImg} className="client" />
              <img src={kingdomImg} className="client" />
            </div>
          </div>
        </div>
        <div className="copyright">Copyright © 2023 UPBOND All rights reserved</div>
      </div>
    </div>
  ) 
}

export default Footer
