import { createSlice } from '@reduxjs/toolkit'

export const userData = {
  id: '',
  is_registered: false,
  is_active: false,
  is_verified: false,
  email: '',
  role: '',
  country: '',
  birthday: '',
  name: '',
  school: '',
  school_country: '',
  school_branch: ''
}

const initialState = {
  user: {
    walletData: {
      isLoggedIn: false,
      walletAddress: ''
    },
    userData
  }
}
export const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user.userData = action.payload
    },
    setWalletData: (state, action) => {
      state.user.walletData = { ...state.user.walletData, ...action.payload }
    }
  }
})
export const { setUserData, setWalletData } = parentSlice.actions
export default parentSlice.reducer
