import * as React from 'react'
import Loadable from 'components/Loadable/index'
import AuthRouter from 'pages/auth/AuthRouter'

// routing for signin & signup
const AuthRoutes = {
  path: 'auth',
  element: <AuthRouter />,
  children: []
}

export default AuthRoutes
