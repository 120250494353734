/* eslint-disable react/prop-types */
import * as React from 'react'

const authContext = React.createContext()

export function useAuth() {
  const [authed, setAuthed] = React.useState(false)
  return {
    authed,
    login() {
      return new Promise((res) => {
        localStorage.getItem('ncca') ? setAuthed(true) : setAuthed(false)
        res(authed)
      })
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false)
        res(false)
      })
    }
  }
}

export function AuthProvider({ children }) {
  const auth = useAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default function AuthConsumer() {
  return React.useContext(authContext)
}
