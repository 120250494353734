import { createSlice } from '@reduxjs/toolkit'

export const adminData = {
  _id: '',
  id: '',
  role: '',
  email: '',
  username: ''
}

const initialState = {
  adminData
}
export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      state.adminData = action.payload
    }
  }
})
export const { setAdminData } = adminSlice.actions
export default adminSlice.reducer
