import Loadable from 'components/Loadable/index'
import MainLayout from 'layouts/MainLayout'
import * as React from 'react'

const Home = Loadable(React.lazy(() => import('pages/home')))
const About = Loadable(React.lazy(() => import('pages/home/about')))
const Roadmap = Loadable(React.lazy(() => import('pages/home/roadmap')))
const PrivacyPolicy = Loadable(React.lazy(() => import('pages/home/privacy-policy')))
const OurTeam = Loadable(React.lazy(() => import('pages/home/our-team')))
const Training = Loadable(React.lazy(() => import('pages/home/training')))
const Guideline = Loadable(React.lazy(() => import('pages/home/guideline')))
const WebEvent = Loadable(React.lazy(() => import('pages/home/web-event')))
const Payment = Loadable(React.lazy(() => import('pages/home/web-event/payment')))
const SuccessPayment = Loadable(React.lazy(() => import('pages/home/web-event/payment/Success')))
const SpecialCommercial = Loadable(React.lazy(() => import('pages/home/special-commercial')))
const TermOfUse = Loadable(React.lazy(() => import('pages/home/term-of-use')))

// routing user after register / login
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/roadmap', element: <Roadmap /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/training', element: <Training /> },
    { path: '/training/:id', element: <Training /> },
    { path: '/training/:type', element: <Training /> },
    { path: '/claim', element: <Training /> },
    { path: '/our-team', element: <OurTeam /> },
    { path: '/guideline', element: <Guideline /> },
    { path: '/special-commercial', element: <SpecialCommercial /> },
    { path: '/term-of-use', element: <TermOfUse /> },
    // { path: '/purchase', element: <WebEvent /> },
    // { path: '/purchase/payment', element: <Payment /> },
    // { path: '/purchase/payment/success', element: <SuccessPayment /> },
  ]
}

export default MainRoutes
