import { LocalizationProvider } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import Routes from 'routes/index'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Toaster } from 'react-hot-toast'

const Routers = () => {
  return (
    <div className="bg-[#FFFFFF]">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Routes />
        <Toaster position="bottom-center" />
      </LocalizationProvider>
    </div>
  )
}

const App = () => {
  return <Routers />
}

export default App
