import React from 'react'
import footerSm from 'assets/images/footer-small.svg'
import logoImg from 'assets/images/logo.svg'
import { useNavigate } from 'react-router'

export const SmallFooter = () => {
  const navigate = useNavigate()

  return (
    <div
      className="w-full h-full flex justify-between"
      style={{
        backgroundImage: `url(${footerSm})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 65,
        padding: '0 30px',
        position: 'fixed',
        bottom: 0
      }}>
      <img src={logoImg} className="w-[100px]" />
      <div className="flex my-[1.5em]">
        <div className="font-normal cursor-pointer text-sm text-[#E0DDDD]" onClick={() => navigate('/privacy-policy')}>Privacy Policy</div>
        <span className="px-2 font-normal text-sm text-[#E0DDDD]"> / </span>
        <div className="font-normal cursor-pointer text-sm text-[#E0DDDD]" onClick={() => navigate('/terms-of-use')}>Terms of Use</div>
      </div>
    </div>
  )
}
