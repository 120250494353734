import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { CaptureConsole } from "@sentry/integrations";
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './hooks/useAuth'
import { BrowserRouter } from 'react-router-dom'
import { Buffer } from 'buffer'
import { Provider } from 'react-redux'
import store from 'storage'
import './styles/index.css'
import App from './App'
import 'tw-elements'

if (typeof window !== 'undefined') {
  window.Buffer = Buffer
  globalThis.Buffer = Buffer
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/n8n\.upbond\.io\/webhook/],
    }),
    new CaptureConsole({
      levels: ['error']
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
});

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
