import React from 'react'
import { Outlet } from 'react-router-dom'
import SmallFooter from '../../components/layouts/footer'

const AuthRouter = () => {
  return (
    <div className="min-h-screen auth-bg pb-[5%]">
      <Outlet />
      <SmallFooter />
    </div>
  )
}

export default AuthRouter
